<!-- Popup -->
<div class="overlay" [ngClass]="{'show-popup': isShown}">
  <div *ngIf="offerObject" class="pricing-card border-0 pt-5 px-4 pb-5 border-rad popup">
    <a class="close" (click)="isShownToggle()">&times;</a>

    <h5 class="font-sm">
      {{offerObject?.title}}
    </h5>
    <img class="offer-image" [src]="offerObject?.image">
    <br>
    <p class="font-weight-light">{{offerObject?.home_title}}</p>
    <!-- <pre class="font-weight-light">{{offerObject?.description}}</pre> -->
    <a (click)="redirectToLink()" class="btn btn-outline-secondary btn-pill btn-lg submit-btn btn-block">Go</a>
  </div>
</div>
