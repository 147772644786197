
<div class="pricing-wrapper">
    <!-- Plans and Pricing section -->
    <div class="container py-5 ">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let slide of logoList" class="slide">
                <a >
                  <img [src] ="slide.image_path" class="img-fluid" alt="" />
                </a>
            </div>
        </ngx-slick-carousel>
    </div><!-- container closed -->
    <div class="pricing-section-1 py-5 bg-light">
    <div class="container  ">
    <div class="row">
        <div class="col-sm-12 empty-cart-cls text-center">
          <h5 class="text-center" translate>Customers everywhere are tired of paying too much for Cable TV and now there is a choice.</h5>  
        </div>
        
        <div class="col-sm-12 col-md-6">
          <p translate>We thought we would fix the problem with the bundling of ‘essential’ channels to cater to the most discerning man, woman, or screaming child.</p>

             <p translate>have two plans. A Skinny Basic Plan & Skinny Plus (+) Plan line up.</p>
              <p translate>We offer a PICK 5 CHANNEL PACK for (or more) starting at $10.00. We can add additional channels to either package.</p>
              <p translate>Both options provide a lineup of the channels you want – all delivered over a private and secure connection from our authorized delivery partners.</p> 

         </div>
         <div class="col-sm-12 col-md-6">
           <h5><div translate>How does it work?</div></h5> 

            <p translate>It is super simple. Instead of a clunky, overpriced set-top-box, We are an “app” on the Apple and Roku store. Just plug your device into your TV, download it, and enter your four (4) digit activation code. Once verified and registered, your network connection will be private [to comply with our content agreements], and that is it. You will be watching high quality, Linear TV for less than you pay today. </p>
         </div>
         <div class="col-sm-12 empty-cart-cls text-center">
         <h6 ><div translate>*If you have multiple TV’s in your home, don’t worry. You can install up to 5 in your billing location.</div></h6>
         </div>
    </div>
    </div>
    </div>
  
    <div class="subscribe-section  py-7">
      <div class="container">
         <div class="row">
            <div class="col-sm-12 col-md-9 mx-auto text-center">
               <div class="mb-5">
                  <h2 class="font-2x text-black"><div translate>Select Province</div> </h2>
                  <p class="lead" translate> Find the right price for your address.Get deals in your neighbourhood </p>
               </div>
               <form class="d-block" [formGroup] = "subscribe" >
                  <div class="form-row justify-content-center">
                     <div class="form-group col-sm-12 col-md-6 col-xs-12">
                        <select name="postalCode" class="form-control  " [formControl]="subscribe.controls['postalCode']" >
                           <option value="Alberta">Alberta</option>
                           <option value="British Columbia">British Columbia</option>
                           <option value="Manitoba">Manitoba</option>
                           <option value="New Brunswick">New Brunswick</option>
                           <option value="Newfoundland and Labrador">Newfoundland and Labrador</option>
                           <option value="Northwest Territories">Northwest Territories</option>
                           <option value="Nova Scotia">Nova Scotia</option>
                           <option value="Nunavut">Nunavut</option>
                           <option value="Ontario">Ontario</option>
                           <option value="Prince Edward Island">Prince Edward Island</option>
                           <option value="Quebec">Quebec</option>
                           <option value="Saskatchewan">Saskatchewan</option>
                           <option value="Yukon">Yukon</option>
                       </select>
                     </div>
                  </div>
                  <div class="form-row justify-content-center">
                     <div class="col-sm-12 col-md-6 col-xs-12">
                      <button type ="submit" class="btn bg-primary btn-block btn-lg submit-btn" (click)="tvPrices(subscribe.value)" translate>Get Packages</button>  
                     </div>
                        
                     
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>
 
    <div class="pricing-section-3">
       <div class="section-gap">
          <div class="container">
             <div class="card-deck">
                <div class="card border-0 border-rad card-shadow m-sm-b" *ngFor="let content of pricingContent">
                   <div class="card-body p-4">
                      <div class="card-header border-0 mb-3">
                         <div class="d-flex justify-content-between">
                            <div class="d-flex align-content-start">
                               <h3 class="m-0 ">{{content?.title}}</h3>
                            </div>
                            <div class="d-flex align-content-end">
                              <h2 class="font-1x px-2 fw-200 mb-4"><sup> {{content?.currency}} </sup>
                                 {{content?.price}}  <sub class="font-sm"> </sub>
                              </h2>
                            </div>
                         </div>
                      </div>
                      <ul class="list-unstyled mb-5">

                        <li class="mb-3" >
                           <i class="fa {{content?.icon1}} mr-2"></i>
                           {{ content?.value1}}
                        </li>
                        <li class="mb-3" >
                           <i class="fa {{content?.icon2}} mr-2"></i>
                           {{ content?.value2}}
                        </li>
                        <li class="mb-3" >
                           <i class="fa {{content?.icon3}} mr-2"></i>
                           {{ content?.value3}}
                        </li>
                        <li class="mb-3" >
                           <i class="fa {{content?.icon4}} mr-2"></i>
                           {{ content?.value4}}
                        </li>
                      </ul>
                      <a  class="btn btn-outline-secondary btn-pill btn-block btn-lg submit-btn"  (click)="addToCart(content)" translate>Buy</a>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
    <div class="pricing-section-1 py-5 bg-light">
      <div class="container  ">
      <div class="row">
          <div class="col-sm-12 col-md-6">
            <div class="row">
               <div class="col-sm-12 col-md-6"> <img src ="/assets/img/AppleTV.png" class="img-fluid" alt="" /></div>
               <div class="col-sm-12 col-md-6"> <img src ="/assets/img/apple_tv.jpeg" class="img-fluid" alt="" /></div>   
               </div>
  
           </div>
           <div class="col-sm-12 col-md-6">
              <div class="row">
               <p translate><strong>Apple TV </strong>is a digital media player and micro-console developed and sold by Apple Inc. It is a small network appliance and entertainment device that can receive digital data from a number of sources and stream it to a capable television. Apple TV is an HDMI-compliant source device.</p>
              </div>
              <div class="row">
                <p translate><strong>Apple TV </strong>allows you to watch paid and free content from the Internet such as ATOP TV, Netflix, Amazon, YouTube, and much more, on your TV.</p> 
            </div>  
            <div class="row">
               <p translate>You can purchase this device at any Bestbuy, Walmart or Source location as well as on-line at various retailers including Amazon.</p> 
           </div> 
           </div>
          
      </div>
      <div class="row">
        
         
         <div class="col-sm-12 col-md-6">
            <div class="row">
            <div class="col-sm-12 col-md-6"> <img src ="/assets/img/roku_logo_50.png" class="img-fluid" alt="" /></div>
            <div class="col-sm-12 col-md-6"> <img src ="/assets/img/roku2b.jpeg" class="img-fluid" alt="" /></div>   
            </div>
            
 
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="row">
               <p translate><strong>Roku</strong> connects to the Internet through your existing Wi-Fi network or Ethernet cable. You will need a Frontier Networks Internet connection to use it. Roku allows you to watch paid and free content from the Internet such as ATOP TV, Netflix, Amazon, YouTube, and much more, on your TV. Roku comes already installed on many TV’s sold today.</p>
              </div>
              <div class="row">
                <p translate>We support the Roku Version 3 or better.</p> 
            </div>  
            <div class="row">
               <p translate>You can purchase this device at any Bestbuy, Walmart or Source location as well as on-line at various retailers including Amazon.</p> 
           </div> 
          </div>
     </div>
      </div>
      </div>
    
 </div>
 
 