import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Featured } from '../interfaces/featured';
import { ReviewSlider } from '../interfaces/reviewSlider';
import { Slider } from '../interfaces/slider';
import { AppService } from '../service/app.service';
import { ChkService } from '../service/chk.service';
declare var $ : any;

@Component({
  selector: 'angly-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  /* Variables */
  public sliders : Slider[] = [];
  homeContent    : any;
  services       : any;
  projectGallary : any;
  posts          : any;
  pricingContent : any;
  contact        : any;
  videoContent   : any;
  mobileFeatured : Featured;
  testimonial    : any;
  public sTitle    = 'sTitle';
  public sSubTitle    = 'sSubTitle';
  public tTitle    = 'tTitle';
  public tSubTitle    = 'tSubTitle';
  public bTitle    = 'bTitle';
  public Start = 'Start at';
  public Buy='Buy Plan';
  public reviewSliders: ReviewSlider[] = [];
  constructor( private service:ChkService,private appService:AppService ,public translate: TranslateService) {

      this.service.getHomeContent().
         subscribe(response => {this.homeContent = response},
                   err      => console.log(err),
                   ()       => this.getContent(this.homeContent)
               );

               const lang = localStorage.getItem('language');
               console.log(lang)
               if (lang === 'fr') {
                  this.appService.getSlidersFR.subscribe(response => {
                     console.log("slider FR")
                   this.sliders = response  
                   console.log(this.sliders)
                  }
                     
                  );
   
                  this.appService.getReviewSliderFR.subscribe(response => 
                     this.reviewSliders = response
                   );
               
                   this.appService.getServicesFR.
                   subscribe(response => {this.services = response},
                     err       => console.log(err),
                     ()        =>this.services
                  );
   
        
                  this.appService.getFeaturedFR().
                  subscribe(response => {this.mobileFeatured = response},
                           err       => console.log(err),
                           ()        =>this.mobileFeatured
                        );
   
         
                  this.appService.getPostsFR.
                      subscribe(response => {this.posts = response},
                     err      => console.log(err),
                     ()       => this.posts
                  );
   
         
                  this.appService.getChannelsGallary.
                  subscribe(response => {this.projectGallary = response},
                      err      => console.log(err),
                      ()       => this.projectGallary
                  );
   
   
         
                  this.appService.getPricingPageContentFR.
                  subscribe(response => {this.pricingContent = response},
                     err       => console.log(err),
                     ()        => this.pricingContent
                  );
   
         
                  this.appService.getVideoCoontentFR().
                  subscribe(response => {this.videoContent= response},
                           err       => console.log(err),
                           ()        => this.videoContent
                        );
               
               } else {
                 
                  this.appService.getSliders.subscribe(response => 
                     this.sliders = response
                    );
   
         
                    this.appService.getReviewSlider.subscribe(response => 
                     this.reviewSliders = response
                   );
               
        
                   this.appService.getServices.
                    subscribe(response => {this.services = response},
                     err       => console.log(err),
                     ()        =>this.services
                  );
   
        
                  this.appService.getFeatured().
                  subscribe(response => {this.mobileFeatured = response},
                           err       => console.log(err),
                           ()        =>this.mobileFeatured
                        );
   
         
                        this.appService.getPosts.
                       subscribe(response => {this.posts = response},
                     err      => console.log(err),
                     ()       => this.posts
                  );
   
         
                  this.appService.getChannelsGallary.
                   subscribe(response => {this.projectGallary = response},
                      err      => console.log(err),
                      ()       => this.projectGallary
                  );
   
   
         
                  this.appService.getPricingPageContent.
                  subscribe(response => {this.pricingContent = response},
                     err       => console.log(err),
                     ()        => this.pricingContent
                  );
   
         
                  this.appService.getVideoCoontent().
                  subscribe(response => {this.videoContent= response},
                           err       => console.log(err),
                           ()        => this.videoContent
                        );
            
               }

               
      this.service.getContactContent().
         subscribe(response => {this.contact = response},
                   err      => console.log(err),
                   ()       => this.contact
               );

      this.service.getHomeTestimonial().
         subscribe(response => {this.testimonial = response},
                   err      => console.log(err),
                   ()       => this.testimonial
               );
      }

  ngOnInit() {
  }


  /*
   * getContent is used for get the home page content.
   * Used variables is videoContent and mobileFeatured.
   */
   getContent(content)
   {
      //this.videoContent    = content.video_content;
      this.mobileFeatured  = content.mobile_featured;
   }

   subscribeFormClasses : any = {rowClass:"row", fieldClass:"col-sm-12 col-md-6"}



}
