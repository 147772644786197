import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { MainComponent }   from './main/main.component';
import { HomeComponent } from './home/home.component';
import { PricingComponent } from './pricing/pricing.component';
import { ContactComponent } from './contact/contact.component';
import { FeaturesComponent } from './features/features.component';
import { AboutComponent } from './about/about.component';
import { SearchComponent } from './search/search.component';
import { SupportComponent } from './support/support.component';
import { sidebarWidgetsComponent } from './sidebarWidgets/sidebarWidgets.component';
import { InternetComponent } from './internet/internet.component';
import { PhoneComponent } from './phone/phone.component';
import { NetworkStatusComponent } from './network-status/network-status.component';
import { TvComponent } from './tv/tv.component';
import { ServicesComponent } from './services/services.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FaqComponent } from './faq/faq.component';

export const AppRoutes: Routes = [{
   
      path: '',
      component: MainComponent,
      children: [
         {
            path: '',
            component: HomeComponent
         },{
            path: 'home',
            component: HomeComponent
         },{
            path: 'blog',
            loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule)
         },{
            path: 'pricing',
            component: PricingComponent
         },{
            path: 'contact',
            component: ContactComponent
         },{
            path: 'services/:id',
            component: ServicesComponent
         },{
            path: 'services',
            component: ServicesComponent
         },{
            path:'features',
            component:FeaturesComponent
         },{
            path:'about',
            component:AboutComponent
         },{
            path:'search',
            component:SearchComponent
         },{
            path:'support',
            component:SupportComponent
         },{
            path:'internet',
            component:InternetComponent
         },{
            path:'phone',
            component:PhoneComponent
         },{
            path:'network-status',
            component:NetworkStatusComponent
         },{
            path:'faq',
            component:FaqComponent
         },{
            path:'tv',
            component:TvComponent
         },{
            path:'terms-conditions',
            component:TermsConditionsComponent
         },{
            path:'privacy-policy',
            component:PrivacyPolicyComponent
         },{
            path: '',
            loadChildren: () => import('./internet/internet.module').then(m => m.InternetModule)
         },{
            path: '',
            loadChildren: () => import('./phone/phone.module').then(m => m.PhoneModule)
         },{
            path: '',
            loadChildren: () => import('./portfolio/portfolio.module').then(m => m.PortfolioModule)
         }, {
            path: '',
            loadChildren: () => import('./testimonial/testimonial.module').then(m => m.TestimonialModule)
         }, {
            path: 'sidebar-widgets',
            component:sidebarWidgetsComponent
         },{
            path: '',
            loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
         },{
            path: '',
            loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
         },{ 
				path: 'about/:keyword',component: AboutComponent 
			}
      ]
}];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
