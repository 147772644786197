import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  
  title = 'app';
  constructor(translate: TranslateService){
    if (localStorage.getItem('language')){
      const lang = localStorage.getItem('language');
      console.log(lang)
      if (lang === 'fr') {
        translate.setDefaultLang('fr');
         translate.use('fr');
   
      } else {
          translate.setDefaultLang('en');
          translate.use('en');
   
      }
    }else{
      translate.setDefaultLang('en');
      translate.use('en');
      
    }
  }
}
