<!-- Instagram Gallery -->
<div class="instagram-gallery">
	<div class="mb-4">
		<h4 class="sidebar-title mb-3">Instagram</h4>
	</div>
	<div class="row">
	   	<div class="col-xs-4 col-sm-4 col-md-4 mb-4" *ngFor="let image of InstagramImages ? InstagramImages.slice(0,6):[]">
		   	<div class="zoom-in">
		      <img [src] ="image.image_path" class="w-100 h-100" width="100" height="100" />
		    </div>
	   	</div>
	</div>
</div>
