import { Component, OnInit, HostListener, Inject, } from '@angular/core';
import { Menu, MenuItems } from '../core/menu/menu-items/menu-items';
import { Router } from '@angular/router';
import { ClickOutside } from '../core/directive/click-outside.directive';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../service/app.service';
import { Pricing } from '../interfaces/pricing';
import { Observable } from 'rxjs';
declare var $: any;

@Component({
  selector: '[angly-menu]',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

	searchactive: boolean = false;
  public products: Pricing[] = [];

	constructor( public router: Router,public translate: TranslateService,public appService:AppService) {
    translate.addLangs(['en', 'fr']);
   
    const lang = localStorage.getItem('language');
      console.log(lang)
      if (lang === 'fr') {
        translate.setDefaultLang('fr');
         translate.use('fr');
      
      } else {
          translate.setDefaultLang('en');
          translate.use('en');    
   
      }
    
   
    this.appService.cartItems.subscribe(response => this.products = response);

    this.appService.getMenuitems.
          subscribe(response => {this.menuItems = response},
               err      => console.log(err),
               ()       => this.menuItems
              );
    

   }
  
   menuItems: Menu[] ;

	ngOnInit() {
   
  }

  changeLang(lang){
    localStorage.setItem('language',lang);
    console.log("change language "+lang)
    if (lang === 'fr') {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
      window.location.reload();
    } else {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
      window.location.reload();
    }
  }

	searchActiveFunction(){
		this.searchactive = !this.searchactive;
	}
   
	onClickOutside(event:Object) {
    if(event && event['value'] === true) {
      this.searchactive = false;
    }
   }
   GoTo(link){
     const re = "%2F"
     const li = link.replace(re,"/");
     this.removeCollapseInClass()
    this.router.navigate(['/'+li]);
   }
	
	public removeCollapseInClass() {
      $("#navbarCollapse").removeClass('show');
   }

   
  get getTotal(): Observable<number> {
    return this.appService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.appService.removeCartItem(product);
  }
}
