import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { map, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AppSetting } from '../interfaces/appSetting';
import { ReviewSlider } from '../interfaces/reviewSlider';
import { Service } from '../interfaces/service';
import { Post } from '../interfaces/post';
import { Menu } from '../core/menu/menu-items/menu-items';
import { Gallary } from '../interfaces/gallary';
import { Featured } from '../interfaces/featured';
import { Pricing } from '../interfaces/pricing';
import { Page } from '../interfaces/page';
import { Slider } from '../interfaces/slider';
import { serviceItem } from '../interfaces/serviceItem';
import { Router } from '@angular/router';
import { Province } from '../interfaces/province';
import { City } from '../interfaces/city';
import { Check } from '../interfaces/check';
import { AV } from '../interfaces/av';
import { VideoContent } from '../interfaces/video';
import { Order } from '../interfaces/order';

const state = {
  packages: JSON.parse(localStorage['products'] || '[]'),
  sliders: JSON.parse(localStorage['sliders'] || '[]'),
  promos: JSON.parse(localStorage['promos'] || '[]'),
  wishlist: JSON.parse(localStorage['wishlistItems'] || '[]'),
  compare: JSON.parse(localStorage['compareItems'] || '[]'),
  cartItems: JSON.parse(localStorage['cartItems'] || '[]'),
  checkoutItems: JSON.parse(localStorage['checkoutItems'] || '[]')
}
@Injectable({
  providedIn: 'root'
})
export class AppService {
  
  public Currency = { name: 'Dollar', currency: 'USD', price: 1 } // Default Currency
  public OpenCart: boolean = false;
  public appSetting: Observable<AppSetting>;
  public featured:Featured;
  public slider:Slider;
  public appS : AppSetting ;

  constructor(private router: Router, private db: AngularFireDatabase,public messageService:ToastrService) { }

  public getAppSetting(): Observable<AppSetting | null> {
    console.log("appcalled")
    return this.db
      .object<AppSetting>('AppSetting')
      .valueChanges()
      .pipe(
        tap((result) => {
          if (result) {
          
            this.appS = result;
            return of(result);
          } else {
            this.messageService.error(`Found no AppSetting`);
            return of(null);
          }
        }),
    
      );
  
  }
  public getVideoCoontent(): Observable<VideoContent | null> {
    console.log("videoContent")
    return this.db
      .object<VideoContent>('videoContent')
      .valueChanges()
      .pipe(
        tap((result) => {
          if (result) {
          
            return of(result);
          } else {
            this.messageService.error(`Found no videoContent`);
            return of(null);
          }
        }),
    
      );
  }

  public getVideoCoontentFR(): Observable<VideoContent | null> {
    console.log("videoContent")
    return this.db
      .object<VideoContent>('videoContent_fr')
      .valueChanges()
      .pipe(
        tap((result) => {
          if (result) {
          
            return of(result);
          } else {
            this.messageService.error(`Found no videoContent`);
            return of(null);
          }
        }),
    
      );
  
  }
  public getProvinces():Observable<Province[]>{
    return this.db
    .list<Province>('province', (ref) => ref)
    .valueChanges()
    .pipe(map((arr) => 
    arr
    
    ));
  }

  public getCities(province):Observable<City[]>{
    return this.db
    .list<City>('canada', (ref) => ref.orderByChild('province').equalTo(province))
    .valueChanges()
    .pipe(map((arr) => 
    arr
    
    ));
  }
  
  public get getSliders(): Observable<Slider[]> {
    return this.sliders;
  }

  
  public get sliders(): Observable<Slider[]> {
    return this.db
      .list<Slider>('slider', (ref) => ref)
      .valueChanges()
      .pipe(map((arr) => 
      arr.reverse()
      
      ));
  }

  public get getSlidersFR(): Observable<Slider[]> {
    return this.slidersFR;
  }

  
  public get slidersFR(): Observable<Slider[]> {

    return this.db
      .list<Slider>('slider_fr', (ref) => ref)
      .valueChanges()
      .pipe(map((arr) => 
      arr.reverse()
      
      ));
  }

  public getFeatured(): Observable<Featured | null> {
    console.log("Featured")
    return this.db
      .object<Featured>('Featured')
      .valueChanges()
      .pipe(
        tap((result) => {
          if (result) {
          
            this.featured = result;
            return of(result);
          } else {
            this.messageService.error(`Found no AppSetting`);
            return of(null);
          }
        }),
    
      );
  
  }

  public getFeaturedFR(): Observable<Featured | null> {
    console.log("Featured")
    return this.db
      .object<Featured>('Featured_fr')
      .valueChanges()
      .pipe(
        tap((result) => {
          if (result) {
          
            this.featured = result;
            return of(result);
          } else {
            this.messageService.error(`Found no AppSetting`);
            return of(null);
          }
        }),
    
      );
  
  }

  public getSlider(): Observable<Slider | null> {
    console.log("Slider")
    return this.db
      .object<Slider>('slider')
      .valueChanges()
      .pipe(
        tap((result) => {
          if (result) {
          
            this.slider = result;
            return of(result);
          } else {
            this.messageService.error(`Found no slider`);
            return of(null);
          }
        }),
    
      );
  
  }

 public get getPricingPageContent(): Observable<Pricing[]> {
  return this.Pricing;
}
public get Pricing(): Observable<Pricing[]> {
  return this.db
    .list<Pricing>('pricing', (ref) => ref)
    .valueChanges()
    .pipe(map((arr) => 
    arr
    
    ));
}

public get getPricingPageContentFR(): Observable<Pricing[]> {
  return this.PricingFR;
}
public get PricingFR(): Observable<Pricing[]> {
  return this.db
    .list<Pricing>('pricing_fr', (ref) => ref)
    .valueChanges()
    .pipe(map((arr) => 
    arr
    
    ));
}


public getTVPricing(province): Observable<Pricing[]> {
  return this.db
    .list<Pricing>('tv', (ref) => ref.orderByChild("province").equalTo(province))
    .valueChanges()
    .pipe(map((arr) => 
    arr
    
    ));
}

public getTVPricingFR(province): Observable<Pricing[]> {
  return this.db
    .list<Pricing>('tv_fr', (ref) => ref.orderByChild("province").equalTo(province))
    .valueChanges()
    .pipe(map((arr) => 
    arr
    
    ));
}


public getsProducts(service:string): Observable<Pricing[]> {
  return this.db
    .list<Pricing>('products', (ref) => ref.orderByChild("service").equalTo(service))
    .valueChanges()
    .pipe(map((arr) => 
    arr
    
    ));
}




  public get getReviewSlider(): Observable<ReviewSlider[]> {
    return this.reviewSliders;
  }
  public get reviewSliders(): Observable<ReviewSlider[]> {
    return this.db
      .list<ReviewSlider>('reviewSlider', (ref) => ref)
      .valueChanges()
      .pipe(map((arr) => 
      arr
      
      ));
  }
  public get getReviewSliderFR(): Observable<ReviewSlider[]> {
    return this.reviewSlidersFR;
  }
  public get reviewSlidersFR(): Observable<ReviewSlider[]> {
    return this.db
      .list<ReviewSlider>('reviewSlider_fr', (ref) => ref)
      .valueChanges()
      .pipe(map((arr) => 
      arr
      
      ));
  }

  public get getServices(): Observable<Service[]> {
    return this.Services;
  }
  public get Services(): Observable<Service[]> {
    return this.db
      .list<Service>('Service', (ref) => ref)
      .valueChanges()
      .pipe(map((arr) => 
      arr
      
      ));
  }

  public get getServicesFR(): Observable<Service[]> {
    return this.ServicesFR;
  }
  public get ServicesFR(): Observable<Service[]> {
    return this.db
      .list<Service>('Service_fr', (ref) => ref)
      .valueChanges()
      .pipe(map((arr) => 
      arr
      
      ));
  }

  public get getPosts(): Observable<Post[]> {
    return this.Posts;
  }
  public get Posts(): Observable<Post[]> {
    return this.db
      .list<Post>('Posts', (ref) => ref)
      .valueChanges()
      .pipe(map((arr) => 
      arr.reverse()
      
      ));
  }

  public get getPostsFR(): Observable<Post[]> {
    return this.PostsFR;
  }
  public get PostsFR(): Observable<Post[]> {
    return this.db
      .list<Post>('Posts_fr', (ref) => ref)
      .valueChanges()
      .pipe(map((arr) => 
      arr.reverse()
      
      ));
  }

  public getAVService(city: string): Observable<AV[]> {
    return this.db
      .list<AV>('cityAL', (ref) => ref.orderByChild("city").equalTo(city))
      .valueChanges()
      .pipe(map((arr) => 
       arr
      
      ));
  }

  
  ifExist(check: Check) :Observable<Check[]> {
    return this.db
    .list<Check>('noService',(ref) => ref.orderByChild("city").equalTo(check.city))
    .valueChanges()
    .pipe(map((arr) => 
      arr.filter(item => item.service=== check.service && item.serviceType === check.serviceType)
      ));
  }
  ifExistCustom(check: Check) :Observable<Check[]> {
    return this.db
    .list<Check>('customPackage',(ref) => ref.orderByChild("city").equalTo(check.city))
    .valueChanges()
    .pipe(map((arr) => 
      arr.filter(item => item.service=== check.service && item.serviceType === check.serviceType)
      ));
  }
  getInternet(provider,type,service,province): Observable<Pricing[]> {
    return this.db
      .list<Pricing>('internet', (ref) => ref.orderByChild("provider").equalTo(provider))
      .valueChanges()
      .pipe(map((arr) => 
      arr.filter(item => item.active=== true && item.service === service && item.type === type&& item.province === province)
      
      ));
  }

  getInternetFR(provider,type,service,province): Observable<Pricing[]> {
    return this.db
      .list<Pricing>('internet_fr', (ref) => ref.orderByChild("provider").equalTo(provider))
      .valueChanges()
      .pipe(map((arr) => 
      arr.filter(item => item.active=== true && item.service === service && item.type === type&& item.province === province)
      
      ));
  }

  getPhone(type,service): Observable<Pricing[]> {
    return this.db
      .list<Pricing>('phone', (ref) => ref.orderByChild("active").equalTo(true))
      .valueChanges()
      .pipe(map((arr) => 
      arr.filter(item =>  item.service === service && item.type === type)
      
      ));
  }

  getPhoneFR(type,service): Observable<Pricing[]> {
    return this.db
      .list<Pricing>('phone_fr', (ref) => ref.orderByChild("active").equalTo(true))
      .valueChanges()
      .pipe(map((arr) => 
      arr.filter(item =>  item.service === service && item.type === type)
      
      ));
  }


  public get getChannelsGallary(): Observable<Gallary[]> {
    return this.Channels;
  }
  public get Channels(): Observable<Gallary[]> {
    return this.db
      .list<Gallary>('ChannelsGalary', (ref) => ref)
      .valueChanges()
      .pipe(map((arr) => 
      arr.reverse()
      
      ));
  }

  public get getMenuitems(): Observable<Menu[]> {
    return this.Menuitems;
  }
  public get Menuitems(): Observable<Menu[]> {
    return this.db
      .list<Menu>('menuItems', (ref) => ref)
      .valueChanges()
      .pipe(map((arr) => 
      arr
      
      ));
  }

  public get getMenuitemsFr(): Observable<Menu[]> {
    return this.MenuitemsFr;
  }
  public get MenuitemsFr(): Observable<Menu[]> {
    return this.db
      .list<Menu>('menuItems_fr', (ref) => ref)
      .valueChanges()
      .pipe(map((arr) => 
      arr
      
      ));
  }

  public getPost(id: string): Observable<Post | null> {
    return this.db
      .object<Post>('Posts/'+id)
      .valueChanges()
      .pipe(
        tap((result) => {
          if (result) {
            console.log("true")
            return of(result);
          
          }
        })
      );
  }

  public getPostFR(id: string): Observable<Post | null> {
    return this.db
      .object<Post>('Posts_fr/'+id)
      .valueChanges()
      .pipe(
        tap((result) => {
          if (result) {
            console.log("true")
            return of(result);
          
          }
        })
      );
  }

  public getPageDetail(id: string): Observable<Page | null> {
    return this.db
      .object<Page>('Page/'+id)
      .valueChanges()
      .pipe(
        tap((result) => {
          if (result) {
            console.log("true")
            return of(result);
          
          }
        })
      );
  }

  public getPageDetailFR(id: string): Observable<Page | null> {
    return this.db
      .object<Page>('Page_fr/'+id)
      .valueChanges()
      .pipe(
        tap((result) => {
          if (result) {
            console.log("true")
            return of(result);
          
          }
        })
      );
  }
  public getOfferDetail(id: string): Observable<Pricing | null> {
    return this.db
      .object<Pricing>('Offer/'+id)
      .valueChanges()
      .pipe(
        tap((result) => {
          if (result) {
            console.log("true")
            return of(result);
          
          }
        })
      );
  }

  public getProductDetail(id: string): Observable<Pricing | null> {
    return this.db
      .object<Pricing>('products/'+id)
      .valueChanges()
      .pipe(
        tap((result) => {
          if (result) {
            console.log("true")
            return of(result);
          
          }
        })
      );
  }

  public getOfferDetailFR(id: string): Observable<Pricing | null> {
    return this.db
      .object<Pricing>('Offer_fr/'+id)
      .valueChanges()
      .pipe(
        tap((result) => {
          if (result) {
            console.log("true")
            return of(result);
          
          }
        })
      );
  }
  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<Pricing[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.cartItems);
      observer.complete();
    });
    return <Observable<Pricing[]>>itemsStream;
  }

  // Add to Cart
  public addToCart(product): any {
    const cartItem = state.cartItems.find(item => item.id === product.id);
    const qty = product.quantity ? product.quantity : 1;
    const items = cartItem ? cartItem : product;
    const stock = this.calculateStockCounts(items, qty);
    
    if(!stock) return false

    if (cartItem) {
        cartItem.quantity = qty    
    } else {
      state.cartItems.push({
        ...product,
        quantity: qty
      })
    }

    this.OpenCart = true; // If we use cart variation modal
    localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    return "true";
  }

  // Update Cart Quantity
  public updateCartQuantity(product: Pricing, quantity: number): Pricing | boolean {
    return state.cartItems.find((items, index) => {
      if (items.id === product.id) {
        const qty = state.cartItems[index].quantity + quantity
        const stock = this.calculateStockCounts(state.cartItems[index], quantity)
        if (qty !== 0 && stock) {
          state.cartItems[index].quantity = qty
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
        return true
      }
    })
  }

    // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity
    const stock = product.stock
    if (stock < qty || stock == 0) {
      this.messageService.error('You can not add more items than available. In stock '+ stock +' items.');
      return false
    }
    return true
  }

  // Remove Cart items
  public removeCartItem(product: Pricing): any {
    const index = state.cartItems.indexOf(product);
    state.cartItems.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    this.messageService.success("Removed from cart")
    return true
  }

  // Total amount 
  public cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(map((product: Pricing[]) => {
      return product.reduce((prev, curr: Pricing) => {
        let price = curr.price;
        return (prev + price ) * this.Currency.price;
      }, 0);
    }));
  }

  // Create order
  public createOrder(product: any, details: any, orderId: any, amount: any) {
    var item = {
        shippingDetails: details,
        product: product,
        orderId: orderId,
        totalAmount: amount
    };
    state.checkoutItems = item;
    localStorage.setItem("checkoutItems", JSON.stringify(item));
    this.sendOrder(item)
    localStorage.removeItem("cartItems");
    this.router.navigate(['/success', orderId]);
  }

  public sendOrder(item:Order){
    this.db.object<Order>('orders/'+item.orderId).update(item);
  }


  // Get Checkout Items
  public get checkoutItems(): Observable<any> {
    const itemsStream = new Observable(observer => {
      observer.next(state.checkoutItems);
      observer.complete();
    });
    return <Observable<any>>itemsStream;
  }
}
