<!-- Page Detail wrapper -->
<div class="blog-detail section-gap pt-5">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-12 mb-3">
				<div class="thumb-content mb-5 blog-content-p">
					<div [innerHTML]= pageDetail?.content></div>
				</div>

		</div>
	</div>
</div>