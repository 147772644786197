<!--  Top menu -->
<nav id="mainNav" class="navbar navbar-expand-md navbar-dark fixed-top bg-primary {{router?.url == '/home' ? '' : 'bg-primary'}}">
	<div class="container">
		<a class="navbar-brand" [routerLink]="['/', 'home']" routerLinkActive="active-link">
			<img src="assets/img/logo.png" class="img-fluid" width="400" height="120">
		</a>
		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="true" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse" id="navbarCollapse">
			<ul menuToggleDirective id="main" class="navbar-nav ml-auto main-menu list-unstyled">
				<li menuToggleLink class="nav-item" routerLinkActive="open" *ngFor="let menuItem of menuItems">
					<a menuToggle class="nav-link menu-font" [routerLink]="['/', menuItem.state]" routerLinkActive="active-link" *ngIf="menuItem.type == 'link'" (click)="removeCollapseInClass()">
						{{ menuItem?.name | translate }}
					</a>
					<a menuToggle class="nav-link menu-font" [href]="menuItem.state" routerLinkActive="active-link" *ngIf="menuItem.type == 'link_e'" >
						{{ menuItem?.name | translate }}
					</a>
					<a menuToggle *ngIf="menuItem.type == 'sub'" class="nav-link menu-font" href="javascript:void(0)">{{ menuItem?.name | translate}}&nbsp;
						<i class="{{ menuItem?.icon }}"></i>
					</a>
					<ul *ngIf="menuItem.type == 'sub' && menuItem?.children" class="sub-menu arrow-up list-unstyled" >
						<li class="nav-item" routerLinkActive="open" *ngFor="let grandchildItem of menuItem?.children">
							<a class="nav-link "  routerLinkActive="active-link" *ngIf="grandchildItem.type == 'link'" (click)="GoTo(grandchildItem.state)">
								{{ grandchildItem?.name | translate}}
							</a>
							
						</li>
					</ul>
				</li>
				<li class="nav-item">
				 <a class="nav-link" [routerLink]="['/product-cart']" (click)="removeCollapseInClass()">
				<img src="assets/img/cart.png"  alt="">
				 </a>
	             <span class="cart_qty_cls">{{ products.length }}</span>
				 </li>	
				<button class="button text-uppercase" igxButton="raised" igxButtonBackground="#ffffff" [igxToggleAction]="dropdown"
               [igxDropDownItemNavigation]="dropdown">{{translate.currentLang}}</button>
                 <igx-drop-down #dropdown >
                 <igx-drop-down-item *ngFor="let lang of translate.getLangs()" [value]="lang" (click)="changeLang(lang)" class="text-uppercase">
                   {{ lang }}
                 </igx-drop-down-item>
                </igx-drop-down>
				
			
						
						  
						
					 
			</ul>
		
				
		</div>

	</div>
</nav>