import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTitleService } from '../core/page-title/page-title.service';
import { Page } from '../interfaces/page';
import { AppService } from '../service/app.service';
import { ChkService } from '../service/chk.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  id:any;
  pageDetail:Page;
  lang='';
  constructor(private pageTitleService: PageTitleService, private service:ChkService,private appService:AppService,private route: ActivatedRoute, private router: Router) { 
	this.lang = localStorage.getItem('language');
    console.log(this.lang)
    
    this.route.params.subscribe(params => {
		if(params.id){
			this.id = params.id;
			this.getPageContent(this.id);
		 	
		}else{
		this.getContent()	
		}
		 });
  }

  getPageContent(id){
	if (this.lang === 'fr') {
		this.appService.getPageDetailFR(id).
		subscribe(response => {
			this.pageDetail = response;
				/* Page title */
		this.pageTitleService.setTitle(this.pageDetail?.title);

		/* Page subTitle */
		this.pageTitleService.setSubTitle(this.pageDetail?.sub_title);
		},
					err => console.log(err),
					() => 
						this.pageDetail
					
			);

	}else{
		this.appService.getPageDetail(id).
		subscribe(response => {
			this.pageDetail = response;
				/* Page title */
		this.pageTitleService.setTitle(this.pageDetail?.title);

		/* Page subTitle */
		this.pageTitleService.setSubTitle(this.pageDetail?.sub_title);
		},
					err => console.log(err),
					() => 
						this.pageDetail
					
			);
	}
		
	}
	getContent(){
		if (this.lang === 'fr') {
			this.appService.getPageDetailFR("services").
			subscribe(response => {
				this.pageDetail = response;
					/* Page title */
			this.pageTitleService.setTitle(this.pageDetail?.title);
	
			/* Page subTitle */
			this.pageTitleService.setSubTitle(this.pageDetail?.sub_title);
			},
						err => console.log(err),
						() => 
							this.pageDetail
						
				);

		}else{
			this.appService.getPageDetail("services").
			subscribe(response => {
				this.pageDetail = response;
					/* Page title */
			this.pageTitleService.setTitle(this.pageDetail?.title);
	
			/* Page subTitle */
			this.pageTitleService.setSubTitle(this.pageDetail?.sub_title);
			},
						err => console.log(err),
						() => 
							this.pageDetail
						
				);

		}
		
	}
  ngOnInit(): void {
  }

}
