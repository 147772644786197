import { Injectable } from '@angular/core';

/*
 * Menu interface
 */
export interface Menu {
	state: string;
	name?: string;
	type?: string;
	icon?: string;
	children?: ChildrenItems[];
}

/*
 * Children menu interface
 */
export interface ChildrenItems {
	state: string;
	name: string;
	type?: string;
}

const HEADERMENUITEMS = [
	{
		state: "home",
		name: "Home",
		type:"link"
	}
	/*,
	{
		state:"",
		name:"Pages",
		type:"sub",
		icon: 'fa fa-caret-down',
		children: [
			{ state: 'about', name: 'About', type:"link"},
			{ state: 'features', name: 'Features', type:"link"},
			{ state: 'contact', name: 'Contact', type:"link"},
			{ state: 'support', name: 'Support', type:"link"},
			{ state: 'pricing', name: 'Pricing', type:"link"},
			{ state: 'search', name: 'Search', type:"link"},
			{ state: 'portfolio-v1', name: 'Portfolio V1', type:"link"},
			{ state: 'portfolio-v2', name: 'Portfolio V2', type:"link"},
			{ state: 'portfolio-v3', name: 'Portfolio V3', type:"link"},
			{ state: 'testimonial-v1', name: 'Testimonial V1', type:"link"},
			{ state: 'testimonial-v2', name: 'Testimonial V2', type:"link"}
		]
	}*/,
	{
		state:"",
		name:"Internet",
		type:"sub",
		icon: 'fa fa-caret-down',
		children: [
		    { state: 'internet-business', name: 'Business', type:"link"},
			{ state: 'internet-residentail', name: 'Residential', type:"link"}
		]
	},
	{
		state:"",
		name:"Phone",
		type:"sub",
		icon: 'fa fa-caret-down',
		children: [
		    { state: 'phone-business', name: 'Business', type:"link"},
			{ state: 'phone-residentail', name: 'Residential', type:"link"}
		]
	},
	{
		state:"",
		name:"TV",
		type:"sub",
		icon: 'fa fa-caret-down',
		children: [
		    { state: 'tv', name: 'Chosse your package', type:"link"}
		]
	},
	{
		state:"",
		name:"Services",
		type:"sub",
		icon: 'fa fa-caret-down',
		children: [
		    { state: 'tv', name: 'Service', type:"link"}
		]
	}/*,
	{
		state:"",
		name:"Features",
		type:"sub",
		icon: 'fa fa-caret-down',
		children: [
			{ state: 'sidebar-widgets', name:'Widgets', type:"link"},
			{ state: 'login', name:'Login', type:"link"},
			{ state: 'sign-up', name: 'Sign Up', type:"link"},
			{ state: 'thank-you', name: 'Thank You', type:"link"},
			{ state: 'maintenance', name: 'Maintenance', type:"link"},
			{ state: 'not-found', name: '404', type:"link"}
		]
	},
	{
		state:"",
		name:"Blog",
		type:"sub",
		icon: 'fa fa-caret-down',
		children: [
			{ state: 'blog-listing-sidebar', name:'blog column ', type:"link"},
			{ state: 'blog-column2', name: 'Blog Column2', type:"link"},
			{ state: 'blog-column3', name: 'Blog Column3', type:"link"},
			{ state: 'blog-masonry2', name: 'Blog Masonry2', type:"link"},
			{ state: 'blog-masonry3', name: 'Blog Masonry3', type:"link"},
			{ state: 'blog-sidebar', name: 'Blog Sidebar', type:"link"},
			{ state: 'blog-no-sidebar', name: 'Blog No Sidebar', type:"link"},
			{ state: 'blog-detail', name: 'Blog Detail', type:"link"}
		]
	}*/
	];

const FOOTERMENU = [
	{
		state: "home",
		name: "Home",
		type:"link"
	},
	{
		state:"about",
		name:"About Us",
		type:"link"
	},
	{
		state:"contact",
		name:"Contact Us",
		type:"link"
	},
	{
		state: "privacy-policy",
		name: "Privacy Policy",
		type:"link"
	},
	{
		state: "terms-conditions",
		name: "Terms & Conditions ",
		type:"link"
	},
	{
		state: "support",
		name: "Support",
		type:"link"
	}
]

const EXPLOREMENU = [
	{
		state: "internet-business",
		name: "Internet",
		type:"link"
	},
	{
		state: "phone-business",
		name: "Phone",
		type:"link"
	},
	{
		state: "tv",
		name: "TV",
		type:"link"
	},
	{
		state: "services",
		name: "Service",
		type:"link"
	},
	{
		state: "blog",
		name: "Blog",
		type:"link"
	},
	{
		state: "sign-in",
		name: "Sign In ",
		type:"link"
	}
];

const FOOTERMENU2 = [
	{
		state: "home",
		name: "Home",
		type:"link"
	},
	{
		state:"sidebar-widgets",
		name:"Widgets",
		type:"link"
	},
	{
		state:"about",
		name:"About",
		type:"link"
	},
	{
		state:"contact",
		name:"Contact",
		type:"link"
	},
	{
		state:"features",
		name:"Features",
		type:"link"
	},
	{
		state:"support",
		name:"Support",
		type:"link"
	},
	{
		state:"search",
		name:"Search",
		type:"link"
	}
];

@Injectable()
export class MenuItems {

	/*
	 * Get all header menu
	 */
	getMainMenu(): Menu[] {
		return HEADERMENUITEMS;
	}

	/*
	 * Get footer menu
	 */
	getFooterMenu(): Menu[] {
		return FOOTERMENU;
	}

	/*
	 * Get the explore menu
	 */
	getExploreMenu(): Menu[] {
		return EXPLOREMENU;
	}

	/*
	 * Get the footer2
	 */
	getFooter2(): Menu[] {
		return FOOTERMENU2;
	}

}
