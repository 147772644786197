import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pricing } from '../interfaces/pricing';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(private db: AngularFireDatabase,) { }

  public get Offers(): Observable<Pricing[]> {
    return this.db
      .list<Pricing>('Offer', (ref) => ref)
      .valueChanges()
      .pipe(map((arr) =>
        arr.reverse()
      ));
  }

  public get OffersFR(): Observable<Pricing[]> {
    return this.db
      .list<Pricing>('Offer_fr', (ref) => ref)
      .valueChanges()
      .pipe(map((arr) =>
        arr.reverse()
      ));
  }
}
