import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTitleService } from '../core/page-title/page-title.service';
import { Page } from '../interfaces/page';
import { AppService } from '../service/app.service';
import { ChkService } from '../service/chk.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {

  id:any;
  pageDetail:Page;
  lang=''
  constructor(private pageTitleService: PageTitleService, private service:ChkService,private appService:AppService,private route: ActivatedRoute, private router: Router) { 
    this.lang = localStorage.getItem('language');
    console.log(this.lang)
			this.id = "terms-conditions";
			this.getPageContent(this.id);
		  
  }

  getPageContent(id){
	if (this.lang === 'fr') {

		this.appService.getPageDetailFR(id).
		subscribe(response => {
			this.pageDetail = response;
				/* Page title */
		this.pageTitleService.setTitle(this.pageDetail?.title);

		/* Page subTitle */
		this.pageTitleService.setSubTitle(this.pageDetail?.sub_title);
		},
					err => console.log(err),
					() => 
						this.pageDetail
					
			);
	}else{

		this.appService.getPageDetail(id).
		subscribe(response => {
			this.pageDetail = response;
				/* Page title */
		this.pageTitleService.setTitle(this.pageDetail?.title);

		/* Page subTitle */
		this.pageTitleService.setSubTitle(this.pageDetail?.sub_title);
		},
					err => console.log(err),
					() => 
						this.pageDetail
					
			);
	}
	
	}
  ngOnInit(): void {
  }

}
