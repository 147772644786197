/*
 * Send message
 * Used in another component.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SendMailService } from '../../../service/send-mail.service';
import { MessageService } from '../../../service/message.service';

@Component({
  selector: '[angly-sendMessage]',
  templateUrl: './sendMessage.component.html',
  styleUrls: ['./sendMessage.component.scss']
})
export class SendMessageComponent implements OnInit {

   sendMessageForm : FormGroup;

   constructor( private formBuilder : FormBuilder , private messageService:MessageService,private sendMail:SendMailService) {

    const to :string[]=['zaidzedoo007@gmail.com'];

      this.sendMessageForm = this.formBuilder.group({
         api_key :"api-3899D92AADF211EC9593F23C91C88F4E",
         to:to,
         subject: "Hello Test Person",
         first_name : [null, [Validators.required] ],
         last_name  : [null, [Validators.required] ],
         sender      : [null, [Validators.required] ],
         phone      : [null, [Validators.required] ],
         text_body   : [null, [Validators.required] ]
      });


     console.log( this.sendMessageForm.value.to)
   }

   

   ngOnInit() {
   }

   /*
    * sendMessage
    */
   sendMessage(values:any)
   {
     if(this.sendMessageForm.valid)
     {
       console.log(values);
       const content = {api_key :"api-992D616EF6E911EB9297F23C91C88F4E",to:["info@egctelecom.ca"],subject: "Contact us",
       text_body: "name :"+values.first_name+" "+values.last_name+"\nphone : "+values.phone+"\nmessage : "+values.text_body,
       sender: values.sender
       }
       this.sendMail.sendMessage(content)
     } else{
       console.log("Error!");
     }
   }

}
