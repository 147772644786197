export class AppSetting {
    public phone: string;
    public address:string;
    public email: string;
    public pinterest : string;
    public facebook: string;
    public twitter: string ;
    public google: string ;
    public insta: string ;
    public android: string ;
    public ios: string ;
    public company: string ;
    public country: string ;
    public isCatSlider: boolean ;
    public isNewProducts: boolean ;
    public isOnSale: boolean ;
    public isBestRated: boolean ;
    public isBlog: boolean ;  
    public linkedin:string;
    public fax:string;

}