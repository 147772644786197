import { Component, OnInit } from '@angular/core';
import { Pricing } from '../interfaces/pricing';
import { OfferService } from '../service/offer.service';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {

  offer: Pricing[] = [];
  offerObject: Pricing;

  // flag for modal visibility
  isShown: boolean;

  constructor(private offerService: OfferService) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.getOfferByLanguage();
    }, 1500);
  }

  getOfferByLanguage() {
    const lang = localStorage.getItem('language');
    if (lang && lang === 'fr') {
      this.getOfferFR();
      return;
    }
    this.getOffer();
  }

  getOffer() {
    this.offerService.Offers.subscribe(res => {
      if (res) {
        const searchAd = res.filter(x => x.show_advertisement == "true") || res;
        this.offerObject = searchAd[0];
        this.offer = res;
        this.isShown = true;
      }
    })
  }

  getOfferFR() {
    this.offerService.OffersFR.subscribe(res => {
      if (res) {
        const searchAd = res.filter(x => x.show_advertisement == "true") || res;
        this.offerObject = searchAd[0];
        this.offer = res;
        this.isShown = true;
      }
    })
  }

  redirectToLink() {
    this.isShownToggle();
    const url = this.offerObject?.url;
    if (url)
      window.open(url, '_blank');
  }

  /**
   * @description: to toggle visibility of popup
   */
  isShownToggle() {
    this.isShown = !this.isShown;
  }
}
