import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DirectivesModule } from './core/directive/directives.module';
import { AgmCoreModule } from '@agm/core';
import { MatMenuModule } from '@angular/material/menu';
/* Routing */
import { AppRoutingModule } from './app-routing.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
/* Service */
import { ChkService } from './service/chk.service';

/* components */
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { HomeComponent } from './home/home.component';
import { PricingComponent } from './pricing/pricing.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { IgxDropDownModule, IgxButtonModule, IgxToggleModule } from "igniteui-angular";
import { MenuItems } from './core/menu/menu-items/menu-items';
import { MenuToggleModule } from './core/menu-toggle.module';
import { PageTitleService } from './core/page-title/page-title.service';
import { WidgetsModule } from './widgets/widgets.module';
import { FeaturesComponent } from './features/features.component';
import { AboutComponent } from './about/about.component';
import { SearchComponent } from './search/search.component';
import { SupportComponent } from './support/support.component';
import { Footer2Component } from './footer2/footer2.component';
import { sidebarWidgetsComponent } from './sidebarWidgets/sidebarWidgets.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ToastrModule } from 'ngx-toastr';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NetworkStatusComponent } from './network-status/network-status.component';
import { TvComponent } from './tv/tv.component';
import { PhoneModule } from './phone/phone.module';
import { ServicesComponent } from './services/services.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { FaqComponent } from './faq/faq.component';
import { CarouselModule } from 'ngx-owl-carousel-o'
import { SliderComponent } from './widgets/slider/slider.component';
import { MessageService } from './service/message.service';
import { SendMailService } from './service/send-mail.service';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { OfferComponent } from './offer/offer.component';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
const config = {
  apiKey: "AIzaSyBC_oFI5beMkq3rOF1W2chTfsro7NdmCg8",
  authDomain: "egctelecome.firebaseapp.com",
  databaseURL: "https://egctelecome-default-rtdb.firebaseio.com",
  projectId: "egctelecome",
  storageBucket: "egctelecome.appspot.com",
  messagingSenderId: "941564714467",
  appId: "1:941564714467:web:12392fa46dc825bdfb54d6",
  measurementId: "G-CKPTD5Q0YQ"
};
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HomeComponent,
    PricingComponent,
    ContactComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    FeaturesComponent,
    AboutComponent,
    SearchComponent,
    SupportComponent,
    Footer2Component,
    sidebarWidgetsComponent,
    NetworkStatusComponent,
    TvComponent,
    SliderComponent,
    ServicesComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    FaqComponent,
    OfferComponent
  ],
  imports: [
    BrowserModule,
    IgxDropDownModule,
    IgxButtonModule,
    IgxToggleModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    WidgetsModule,
    MenuToggleModule,
    DirectivesModule,
    SlickCarouselModule,
    MatMenuModule,
    CarouselModule,
    NgxPopperjsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-bottom-right'
    }) ,
    AngularFireModule.initializeApp(config),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBC_oFI5beMkq3rOF1W2chTfsro7NdmCg8'
    }),
  ],
  providers: [
    MenuItems,
    PageTitleService,
    ChkService,
    MessageService,
    SendMailService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
