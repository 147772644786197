import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTitleService } from '../core/page-title/page-title.service';
import { Page } from '../interfaces/page';
import { AppService } from '../service/app.service';
import { ChkService } from '../service/chk.service';

@Component({
	selector: 'angly-about',
	templateUrl: './about.component.html',
	styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

	
	id:any;
	pageDetail:Page;
	constructor(private pageTitleService: PageTitleService, private service:ChkService,private appService:AppService,private route: ActivatedRoute, private router: Router) { 
	  
			  this.id = "about";
			  this.getPageContent(this.id);
			
	}
  
	getPageContent(id){
		  this.appService.getPageDetail(id).
		  subscribe(response => {
			  this.pageDetail = response;
				  /* Page title */
		  this.pageTitleService.setTitle(this.pageDetail?.title);
  
		  /* Page subTitle */
		  this.pageTitleService.setSubTitle(this.pageDetail?.sub_title);
		  },
					  err => console.log(err),
					  () => 
						  this.pageDetail
					  
			  );
	  }
	ngOnInit(): void {
	}
}
