<div class="page-main-container">
	
	<!-- Service grid -->
	<div class="service section-gap">
		<div class="container">
			<div class="section-title-wrapper">
			  <div class="row">
					<div class="col-sm-12 col-md-10 mx-auto text-center">
						<h2>{{ sTitle | translate }}</h2>
						<p class="mb-0">{{sSubTitle | translate}}</p>
					</div>
			  </div>
			</div>
			<div class="row" angly-serviceGrid [serviceGridContent]="services"></div>
		</div>
	</div>
	<!-- Video section -->
	<div class="chk-video-section section-gap bg-light">
		<div class="container">
		  <div class="row" angly-chkVideo [videoContent]="videoContent">
		  </div>
		</div>
	</div>
	<!-- Featured section -->
	<div class="mob-feature">
		<div class="section-gap">
			<div class="container">
				<div class="section-title-wrapper">
				  <div class="row">
						<div class="col-sm-12 col-md-10 mx-auto text-center text-white">
							<h2 class="text-white"> {{mobileFeatured?.title}} </h2>
							<p class="mb-0">{{mobileFeatured?.sub_title}} </p>
						</div>
				  </div>
				</div>
				<div class="row" angly-mobileFeatured [mobileFeatured]="mobileFeatured"></div>
			</div>
		</div>
	</div>
	<!-- Projects grid-->
	<div class="Gallery-grid section-gap">
		<div class="container">
			<div class="section-title-wrapper">
				<div class="row">
					<div class="col-sm-12 col-md-10 mx-auto text-center">
						<h2> {{tTitle | translate}} </h2>
						<p class="mb-0">
							{{tSubTitle | translate}}
						</p>
					</div>
				</div>
			</div>
			<div class="row" angly-gallaryGrid gridClass="col-sm-12 col-md-4 mb-4" [gallaryGridContent]="projectGallary"></div>
		</div>
	</div>
	
	
	<!-- Pricing grid -->
	<div class="pricing-grid">
		<div class="container">
			<div class="section-title-wrapper">
				<div class="row">
					<div class="col-sm-12 col-md-10 mx-auto text-center">
						<h2 class="text-white"><div translate>Plans and Pricing</div>  </h2>
					</div>
				</div>
			</div>
		  <div class="row">
				<div class="col-sm-12 col-md-4 col-lg-4 mb-4" *ngFor="let content of pricingContent">
					<div class="pricing-card border-0 pt-5 px-4 pb-4 border-rad">
						<span class="mb-4 {{content?.bg_color}} p-2 px-3 btn-pill text-center d-inline-block"> {{content?.title}} </span>
						<p>{{Start | translate}}</p>
						<h2 class="font-4x px-2 fw-200 mb-4"><sup class="font-xs"> {{content?.currency}} </sup>
						{{content?.price}} <sub class="font-sm"> </sub></h2>
						<ul class="list-unstyled pricing-list mb-5">
							<li class="mb-3" >
								<i class="fa {{content?.icon1}} mr-2"></i>
								{{ content?.value1}}
							 </li>
							 <li class="mb-3" >
								<i class="fa {{content?.icon2}} mr-2"></i>
								{{ content?.value2}}
							 </li>
							 <li class="mb-3" >
								<i class="fa {{content?.icon3}} mr-2"></i>
								{{ content?.value3}}
							 </li>
							 <li class="mb-3" >
								<i class="fa {{content?.icon4}} mr-2"></i>
								{{ content?.value4}}
							 </li>
						</ul>
						<a routerLink="{{content.url}}" class="btn btn-outline-secondary btn-pill btn-lg submit-btn btn-block">{{Buy |translate}}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Posts grid -->
	<div class="post-section bg-light section-gap">
		<div class="container">
			<div class="section-title-wrapper">
				<div class="row">
					 <div class="col-sm-12 col-md-10 mx-auto text-center">
						<h2> {{bTitle | translate}}</h2>
					 </div>
				</div>
			</div>
			<div class="card-deck">
				<div class="row" angly-postGrid [postGridContent]="posts"></div>
			</div>
		</div>
	</div>
	<!-- Testimonial -->
	<div class="testimonial-section section-gap">
		<div class="">
			<div class="container">
				<div angly-testimonial [testimonialContent]="reviewSliders"></div>
			</div>
		</div>
	</div>
	<!-- Subscribe section -->
	<div angly-subscribe [subscribeFormClasses]="subscribeFormClasses"></div>



</div>
