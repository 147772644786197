<!-- Slick slider Client logo list -->
<div class="container">
	<ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (afterChange)="afterChange($event)">
	    <div ngxSlickItem *ngFor="let slide of logoList" class="slide">
	    	<a href="javascript:void(0);">
	          <img src="{{ slide?.logo_path }}" class="img-fluid" alt="client logo" />
	        </a>
	    </div>
	</ngx-slick-carousel>
</div><!-- container closed -->

