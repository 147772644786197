<div *ngIf="router?.url != '/sign-up' && router?.url != '/login' && router?.url != '/thank-you' && router?.url != '/not-found' && router?.url != '/maintenance'">


	<!-- Footer  -->
	<div class="footer-wrapper bg pt-5">
		<div class="container">
			<div class="row">
				<div class="col-sm-6 col-md-6 col-lg-4 mb-3">
					<h5 class="mb-3 text-capitalize text-white">{{ Company |translate }}</h5>
					<div angly-menuBlock [footerMenu]="footerCompanyMenu">
					</div>
				</div>
				<div class="col-sm-6 col-md-6 col-lg-4 mb-3">
					<h5 class="mb-3 text-capitalize text-white">{{ Services | translate }}</h5>
					<div angly-menuBlock [footerMenu]="footerExploreMenu">
					</div>
				</div>
				<div class="col-sm-12 col-md-12 col-lg-4 mb-3">
					<h5 class="mb-3 text-capitalize text-white">{{ StayInTouch | translate }}</h5>
					<div class="address" angly-contact location="{{appSettingContent?.address}}" tel="{{appSettingContent?.phone}}" fax = "{{appSettingContent?.fax}}" mail="{{appSettingContent?.email}}">
					</div>
				</div>
			</div>
			<div class="footer-bottom">
				<div class="row">
					<div class="col-sm-12 col-md-6">
						<span class="text-white">&copy; 2021, All right reserved </span>
					</div>
					<div class="social-icon-widget mb-2 col-sm-12 col-md-6 text-right">
						<ul class="list-inline mb-0">
							<li class="list-inline-item"><a href="{{appSettingContent?.facebook}}" class="square-30 rounded-circle bg-facebook"><i class="fa fa-facebook fa-inverse"></i></a></li>
							<li class="list-inline-item"><a href="{{appSettingContent?.twitter}}" class="square-30 rounded-circle bg-twitter"><i class="fa fa-twitter fa-inverse"></i></a></li>
							<li class="list-inline-item"><a href="{{appSettingContent?.google}}" class="square-30 rounded-circle bg-google"><i class="fa fa-google-plus fa-inverse"></i></a></li>
							<li class="list-inline-item"><a href="{{appSettingContent?.linkedin}}" class="square-30 rounded-circle bg-linked"><i class="fa fa-linkedin fa-inverse"></i></a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Back To Top -->
	<a href="#" id="back-top" class="to-top scrolled" (click)="topScroll($event)"> <span class="to-top-icon"></span> </a>
</div>

