<!-- Mobile Feature -->
<div class="col-sm-12 col-md-6 text-center">
   <img [src]="mobileFeatured?.image" alt="mobile feature" class="img-fluid" width="328" height="624">
</div>
<div class="col-sm-12 col-md-6 pt-5">
	<ul class="list-unstyled text-white">
		<li class="media mb-5" >
			<span class="mr-5 d-flex">
				<i class="text-success ion-ios-color-wand font-4x"></i>
			</span>
			<div class="media-body">
				<h5 class="mb-3 text-white">{{mobileFeatured?.title1}}</h5>
				<p>{{mobileFeatured?.line1}}</p>
			</div>
		</li>
		<li class="media mb-5" >
			<span class="mr-5 d-flex">
				<i class="text-success ion-ios-color-wand font-4x"></i>
			</span>
			<div class="media-body">
				<h5 class="mb-3 text-white">{{mobileFeatured?.title2}}</h5>
				<p>{{mobileFeatured?.line2}}</p>
			</div>
		</li>
		<li class="media mb-5" >
			<span class="mr-5 d-flex">
				<i class="text-success ion-ios-color-wand font-4x"></i>
			</span>
			<div class="media-body">
				<h5 class="mb-3 text-white">{{mobileFeatured?.title3}}</h5>
				<p>{{mobileFeatured?.line3}}</p>
			</div>
		</li>
		<li class="media mb-5" >
			<span class="mr-5 d-flex">
				<i class="text-success ion-ios-color-wand font-4x"></i>
			</span>
			<div class="media-body">
				<h5 class="mb-3 text-white">{{mobileFeatured?.title4}}</h5>
				<p>{{mobileFeatured?.line4}}</p>
			</div>
		</li>
	</ul>
</div>
