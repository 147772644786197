import { Component, OnInit, OnDestroy, ViewChild, HostListener, Inject, ElementRef, ViewEncapsulation } from '@angular/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { ChkService } from '../service/chk.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import {AppSetting} from '../interfaces/appSetting'
import {AppService} from '../service/app.service'
import { Slider } from '../interfaces/slider';
declare var $ : any;

@Component({
    selector: 'angly-layout',
  	templateUrl:'./main.component.html',
  	styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit{

	 private _router: Subscription;
	 url: string;
	 private _mediaSubscription: Subscription;
	 private _routerEventsSubscription: Subscription;
	 isHome : boolean = true;
	 isBlog : boolean = false;
	 isCommon : boolean = false;
	 fixedHeaderClass : boolean = false;
	 Network_Status = 'Network Status'
	public appSetting : AppSetting;
	/* Variables */
	headerTitle    : string;
	headerSubTitle : string;
	featuredPost   : any;
	public Check_Availability = 'Check Availability'
	//slider:any;
	public sliders : Slider[] = [];
	private AppSettingSubscription: Subscription;

	constructor(private pageTitleService: PageTitleService, private service:ChkService, public router: Router,public appService:AppService) {
	
		/* page title.*/
			this.pageTitleService.title.subscribe((val: string) => {
			this.headerTitle = val;
		});

		/* page subTitle. */
			this.pageTitleService.subTitle.subscribe((val: string) => {
			this.headerSubTitle = val;
		});

		this.service.getFeaturedPost().
			subscribe(response => {this.featuredPost = response},
			          err    => console.log(err),
			          ()     => this.featuredPost
			       );
				   const lang = localStorage.getItem('language');
				   console.log(lang)
				   if (lang === 'fr') {
	this.appService.getSlidersFR.subscribe(response => 
					this.sliders = response
				  );
	}else{
		this.appService.getSliders.subscribe(response => 
			this.sliders = response
		  );	
	}
	/* this.appService.getSlider().
				   subscribe(response => {this.slider = response},
							err       => console.log(err),
							()        =>this.slider
						 );
						 */
	  this.AppSettingSubscription = this.appService.getAppSetting().subscribe((appSetting: AppSetting) =>{
					if(appSetting){
					  this.appSetting = appSetting
					  console.log(this.appSetting)
					
					}    
				 }); 

				 
	}

	ngOnInit() { }
	
	@HostListener('scroll', ['$event'])
	onScroll(event) {
		  if(event.path && (event.path[0].scrollTop > 0)){
		  		this.fixedHeaderClass = true;
		  }else{
		  		this.fixedHeaderClass = false;
		  }
	}

	onActivate(e, scrollContainer) {
		scrollContainer.scrollTop = 0;
		window.scroll(0,0);
   }

   addToggleClass() {
	this.router.navigate(['/network-status']);
   }

}
