<div class="contact-widget section-gap">
	<div class="container">
		<div class="row">
				<div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3 text-center">
				<div class="contact-icon mb-4">
					<i class="fa fa-phone text-warning fa-3x"></i>
				</div>
				<h4 class="text-warning mb-3" translate> Call Us </h4>
				<div >
					<h5 *ngIf="contact?.phone">
						<a class="text-dark" >{{contact?.phone}}</a>
					</h5>
				</div>
				</div>
				<div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3 text-center">
					
				</div>
			<div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3 text-center">
				<div class="contact-icon mb-4">
				<i class="fa fa-envelope-o text-warning fa-3x"></i>
				</div>
				<h4 class="text-warning mb-3" translate> Send Us Mail </h4>
				<div >
					<h5 >
						<a class="text-dark" href="mailto:{{contact?.email}}">{{contact?.email}}</a>
					</h5>
				</div>
			</div>
			
		</div>
	</div>
</div>
<div class="contact-info">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-7">
				<!-- Google Map -->
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.296910938011!2d-73.82555348436279!3d45.463822541489506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc93c6f246529bd%3A0x3cca3a9369934a4!2s6500%20Trans-Canada%20Hwy%20%23%20400%2C%20Pointe-Claire%2C%20QC%20H9R%200A5!5e0!3m2!1sen!2sca!4v1627855385214!5m2!1sen!2sca" width="600" height="800" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
			</div>
			<div class="col-sm-12 col-md-12 col-lg-5">
				<div angly-sendMessage></div>
			 </div>
		 </div>
	</div>
</div>
<div class="follow-us text-center section-gap">
	<div class="container">
		<div class="mb-5">
			<h2 > <div translate>Follow Us</div> </h2>
		</div>
		<div class="social-icon-widget">
			<ul class="list-inline mb-0">
				<li class="list-inline-item mx-2"><a href="{{contact.facebook}}" class="square-60 rounded-circle bg-facebook"><i class="fa fa-facebook fa-inverse"></i></a></li>
				<li class="list-inline-item mx-2"><a href="{{contact.twitter}}" class="square-60 rounded-circle bg-twitter"><i class="fa fa-twitter fa-inverse"></i></a></li>
				<li class="list-inline-item mx-2"><a href="{{contact.google}}" class="square-60 rounded-circle bg-google"><i class="fa fa-google-plus fa-inverse"></i></a></li>
				<li class="list-inline-item mx-2"><a href="{{contact.linkedin}}" class="square-60 rounded-circle bg-linked"><i class="fa fa-linkedin fa-inverse"></i></a></li>
			</ul>
		</div>
	</div>
</div>