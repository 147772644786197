<!-- Testimonial Slider -->
<div id="testimonial-slide" class="carousel slide" data-ride="carousel">
   <div class="carousel-inner">
      <div class="row">
         <div class="col-sm-12 col-md-7 mx-auto">
            <div class="carousel-item active text-center">
               <div class="mb-4">
                  <h5 class="text-white" *ngIf="testimonialContent">Reivews and Comments</h5>
                  
               </div>
               <p class="text-white text-center"></p>
            </div>
            <div class="carousel-item text-center" *ngFor="let content of testimonialContent">
               <div class="mb-4">
                  <h5 class="text-white">{{content?.name}}</h5>
                  <span class="text-white font-sm">{{content?.type}}</span>
               </div>
               <p class="text-white text-center"> {{content?.content}}</p>
            </div>
         </div>
      </div>
   </div>
   <a class="carousel-control-prev" href="#testimonial-slide" role="button" data-slide="prev">
   	<span class="carousel-control-prev" aria-hidden="true">
         <i class="ion-ios-arrow-dropleft-circle font-2x"></i>
      </span>
   	<span class="sr-only">Previous</span>
   </a>
   <a class="carousel-control-next" href="#testimonial-slide" role="button" data-slide="next">
   	<span class="carousel-control-next" aria-hidden="true">
         <i class="ion-ios-arrow-dropright-circle font-2x"></i>  
      </span>
   	<span class="sr-only">Next</span>
   </a>
</div>
<!-- Testimonial Slider Closed -->

