import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { AppService } from '../service/app.service';
import { ChkService } from '../service/chk.service';

@Component({
  selector: 'angly-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  /* Variables */
  contact : any;

  lat: number = 30.67995;
  lng: number = 76.72211;
lang = '';
  constructor( public translate:TranslateService,private pageTitleService: PageTitleService, private appService:AppService ) {

    this.lang = localStorage.getItem('language');
    console.log(this.lang)
    let title = '';
    let subTitle = '';
    if (this.lang === 'fr') { 
   translate.get('Lets Get In Touch').subscribe((res: string) => {
    console.log(res);
      title = res;
    });
   translate.get('Our latest news and learning articles.').subscribe((res: string) => {
   console.log(res);
   subTitle = res;
   });

   this.pageTitleService.setTitle(title);

   /* Page subTitle */
   this.pageTitleService.setSubTitle(subTitle);
 
    }else{

   translate.get('Lets Get In Touch').subscribe((res: string) => {
    console.log(res);
    title = res;
    });
   translate.get('Our latest news and learning articles.').subscribe((res: string) => {
    console.log(res);
    subTitle = res;
    });
   this.pageTitleService.setTitle(title);
   this.pageTitleService.setSubTitle(subTitle);
 
    }
	
    this.appService.getAppSetting().
		subscribe(response => {this.contact = response},
					 err      => console.log(err),
					 ()       => this.contact
				);
	 
  }

  ngOnInit() {
  }

}
