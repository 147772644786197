import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PageTitleService } from '../core/page-title/page-title.service';
import { Gallary } from '../interfaces/gallary';
import { AppService } from '../service/app.service';
import { ChkService } from '../service/chk.service';
import { MessageService } from '../service/message.service';

@Component({
  selector: 'app-tv',
  templateUrl: './tv.component.html',
  styleUrls: ['./tv.component.css']
})
export class TvComponent implements OnInit {
 /* Variables */
 pricingContent : any;
 logoList  :any;
 subscribe : FormGroup;
 lang='';
 constructor(public translate:TranslateService, private pageTitleService: PageTitleService, private service:ChkService,private appService:AppService,private formBuilder : FormBuilder , private router: Router,private messageService:MessageService) {
  this.lang = localStorage.getItem('language');
  console.log(this.lang)
  let title = '';
  let subTitle = '';
 
   if(this.lang === 'fr'){
     translate.get('Live and on demand TV').subscribe((res: string) => {
      console.log(res);
      title = res;
    });
    translate.get('Subscribe directly to just the TV and stream your favorite live and on-demand movies and shows directly to the device of your choice').subscribe((res: string) => {
      console.log(res);
      subTitle = res;
    });
 /* Page title */
   this.pageTitleService.setTitle(title);

   /* Page subTitle */
   this.pageTitleService.setSubTitle(subTitle);
   }else{
    translate.get('Live and on demand TV').subscribe((res: string) => {
      console.log(res);
      title = res;
    });
    translate.get('Subscribe directly to just the TV and stream your favorite live and on-demand movies and shows directly to the device of your choice').subscribe((res: string) => {
      console.log(res);
      subTitle = res;
    });
/* Page title */
this.pageTitleService.setTitle(title);

/* Page subTitle */
this.pageTitleService.setSubTitle(subTitle);
   }
  
   this.subscribe = this.formBuilder.group({
    postalCode : ["Select Province", [Validators.required] ]
 });

  
            this.service.getFooterLogoList().
            subscribe(response => {this.logoList = response},
                      err      => console.log(err),
                      ()       => this.logoList
                  );

                  this.service. getProjectGallary().
                  subscribe(response => {this.logoList = response},
                            err      => console.log(err),
                            ()       => this.logoList
                        );      
  }

 ngOnInit() {
   
   }

   tvPrices(value:any)
  {
     if(this.subscribe.valid)
     {
        console.log(value.postalCode);
        this.pricingContent=[]
        this.getPrices(value.postalCode)
     } else{
        console.log("Error!");
     }
  }

  getPrices(value:string){
    if (this.lang === 'fr') {
      this.appService.getTVPricingFR(value).
      subscribe(response => {
        this.pricingContent = response;
        this.pricingContent.sort((a,b) => a.price - b.price);
      },
               err => console.log(err),
               () => this.pricingContent
             );

    }else{
      this.appService.getTVPricing(value).
     subscribe(response => {
      this.pricingContent = response;
      this.pricingContent.sort((a,b) => a.price - b.price);
     },
              err => console.log(err),
              () => this.pricingContent
            );
    }
    
  }
   // Add to cart
   async addToCart(product: any) {
    const status = await this.appService.addToCart(product);
    if(status)
      this.router.navigate(['/product-list/tv']);
      this.messageService.add("Added to cart");
  }
 slideConfig = {	
  "slidesToShow": 5, 
  "slidesToScroll": 1,
  "autoplay": true,
  "autoplaySpeed": 2000,
  "arrows": false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1
      }
    }
]
 };



}
