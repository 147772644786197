<!-- Search now -->
<div class="search section-gap">
    <div class="row">
       <div class="col-sm-8 col-md-5 mx-auto">
          <angly-searchNow></angly-searchNow>
       </div>
    </div>
 </div>
 <!-- Support topics -->
 <div class="support-ques mb-5">
    <div class="container" *ngIf="supportContent?.support_topics">
       <div class="row">
          <div class="col-sm-12 col-md-4 mb-3" *ngFor="let support of supportContent?.support_topics">
             <h5 class="mb-4"> {{support?.heading}}</h5>
             <div *ngFor="let content of support?.content">
                <p class="font-sm"><a href="javascript:void(0)"> {{content}} </a></p>
             </div>
          </div>
       </div>
    </div>
 </div>
 <!-- Suppots cards -->
 <div class="support-card mb-5">
    <div class="container" *ngIf="supportContent?.supports">
       <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6 mb-3" *ngFor="let support of supportContent?.supports">
             <div class="card card-shadow border-rad">
                <div class="card-body p-4">
                   <div class="d-flex justify-content-between">
                      <div class="flex-column mr-5">
                         <h5> {{support?.heading}} </h5>
                         <p class="text-justify"> {{support?.content}} </p>
                         <a href="javascript:void(0)" class="btn btn-primary"> {{support?.button_content}}</a>
                      </div>
                      <div class="d-flex align-items-center d-xs-none">
                         <img [src]="support?.image_path" />
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>
 <!-- Accordion -->
 <div class="frequently-section section-gap bg-light">
    <div class="container" *ngIf="supportContent?.support_questions">
       <div class="section-title-wrapper mb-5">
          <div class="row">
             <div class="col-sm-12 col-md-9 mx-auto text-center">
                <h2 class="mb-3"> Frequently Asked Questions </h2>
                <p>Don’t worry, we are always here to assist you.</p>
             </div>
          </div>
       </div>
       <div class="accordion-wrapper">
          <div id="accordion" role="tablist">
             <div class="simple-line-accordion">
                <div class="card card-white bg-light mb-2" *ngFor="let support of supportContent?.support_questions">
                   <div class="card-header" role="tab">
                      <h5 class="mb-0">
                         <a class="collapsed accordion-toggle" data-toggle="collapse" href="#{{support?.collapse_id}}" aria-expanded="true" aria-controls="collapseTwo">
                            {{support?.question}}
                         </a>
                      </h5>
                   </div>
                   <div id="{{support?.collapse_id}}" class="collapse" role="tabpanel" data-parent="#accordion">
                      <div class="card-body">
                         {{support?.answer}}
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>
